import React, {useEffect, useState} from 'react'
import '../../assets/css/newPFOffer/IngresoProductTable.css'
import op from '../../assets/img/tradicional/op.svg'
// import {OfertaIntelligence} from '../OfertaIntelligence'
import {SwitchButton} from './SwitchButton'
import {formatNumberES, redondearDecena} from '../../config'


export const ProductTableInnerTradicional = ({data, activateOffice, offerId, rowChangeFunctionDescOffice, 
	setModeloPopupState, setProduct}) => {
	const [estr, setestr] = useState(data.strat_price)
	const [active, setactive] = useState(data.active_office)
	const [onEdit, setOnEdit] = useState(false)

	useEffect(() => {
		setestr(data.strat_price)
		setactive(data.active_office)
	}, [data])

	return (
		<>
			{ // active && <OfertaIntelligence setactive={setactive} />
			}

			<tr className='ProductTableInner familia1 !h-[30px]'>
				<td></td>
				<td className='border-left'></td>
				<td colSpan={3} className='border-r-[2px] border-[#B6D8EE]'>
					<div className='border-center'></div>
				</td>

				<td>
					<div className='display_flex  gray '>
						<p>
						<a
							href='#'
							onClick={(e) => {
								setProduct(data)
								setModeloPopupState(true)
							}}
						>
							{data.distributor_name}
							</a>
							</p>
					</div>
				</td>

				<td className='border-gray-custom-blue'>
					<div className='display_flex gray' >
						{ data && <SwitchButton active={active} setActive={setactive} activateOffice={activateOffice}
							id_office={data.id} offerId = {offerId}
						/>}
					</div>
				</td>

				
				<td>
					<div className='display_flex gray'>
						<p>{data.curr_vol}</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p>{data.opt_vol}</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p>{data.strat_vol}</p>
					</div>
				</td>
				<td className='border-gray-custom-blue'>
					<div className='display_flex gray'>
						<p>{data.strat_io_vol}</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<p>{data.curr_price}</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p>{data.opt_price}</p>
					</div>
				</td>
				<td>
					<div
						className='head_box wrap_input'
						style={{transform: 'translateX(5px)'}}
					> <span style={{fontSize: 11}}>$</span>
						<input
							id={"office_"+data.id}
							type='text'
							className='!h-[14px]'
							value={String(estr).replace("$","")}
							onChange={(e) => {
								setOnEdit(true)
								setestr(e.target.value)
							}}
							// formatNumberES
							onKeyUp={(e) =>{
								if (e.key === 'Enter') {
									setOnEdit(false)
									setestr(formatNumberES(e.target.value.replace("$","").replace(".","").replace(",",".")))
									e.target.blur();
									rowChangeFunctionDescOffice(data.id, e.target.value)
								}	
							}}	
							onBlur={(e) => {
								// Lógica que quieres ejecutar cuando se presiona Enter
								if(onEdit){
									// alert(e.target.value.replace("$","").replace(".","").replace(",","."))
									setOnEdit(false)
									setestr(formatNumberES(e.target.value.replace("$","").replace(".","").replace(",",".")))
									e.target.blur();
									rowChangeFunctionDescOffice(data.id, e.target.value)
								}
							}}	
						/>
					</div>
				</td>
				<td className='border-gray-custom-blue'>
					<div className='display_flex gray '>
						<p>{data.strat_curr}</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<p>{data.curr_mg}</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p>{data.opt_mg}</p>
					</div>
				</td>

				<td className='border-gray-custom-blue'>
					<div className='display_flex gray'>
						<p>{data.strat_mg}</p>
					</div>
				</td>

				<td>
					<div className='display_flex   gray'>
						<p className=' !text-[#FF5334]'>{data.strat_ro_pct}</p>
					</div>
				</td>
				<td>
					<div className='display_flex   gray'>
						<p className='ten'>{data.uplift}</p>
					</div>
				</td>
				<td>
					<div className='display_flex   gray'>
						<p className='ten'>{data.incr_ro}%</p>
					</div>
				</td>
				<td>
					<div className='display_flex   gray'>
						<p className='ten'>{data.recommended_pvp}</p>
					</div>
				</td>

				<td className='border-gray-custom-blue'>
					<div className='display_flex gray'>
						<p className='ten'>{data.elasticity}</p>
					</div>
				</td>
				<td></td>

				<td>
					<div className='display_flex gray'>
						{ /*
						<img
							src={op}
							alt=''
							onClick={(e) => {
								setactive(true)
							}}
							style={{cursor: 'pointer'}}
							className='-translate-x-[5px]'
						/>
						*/ }
					</div>
				</td>
			</tr>
		</>
	)
}
