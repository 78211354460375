import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table'
import {PureTableProductsRow} from './PureTableProductsRow'
import {config} from '../../config'


export const TableProductsPure = ({setCodigoPop, idOferta, products,
	catalogo,
	oferta,
	palabra,
	estrategia,
	linea,
	familia,
	active
}) => {
	
	let count = 0;
	

	return (
		<div className='CataLogoResumenTable VienesasTable'>
			
			<Table id="listadoProductos">
				<thead style={{background: '#E8E8E8', height: 30}}>
					<tr>
						<td>
							<div className='display_flex gray'>
								<p>Estr.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Código</p>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray'>
								<p>Descripción</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>Línea</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Familia</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>SubFam.</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>Marca</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>Form.</p>
							</div>
						</td>
						<td>
							<div className='display_flex gray'>
								<p>Elast.</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>$ Bas.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>$ Estr.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>%Dcto.</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>$ Crit.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>RO.Estr.</p>
							</div>
						</td>

						<td>
							<div className='display_flex  gray'>
								<p>Cat.</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray'>
								<p>Of.</p>
							</div>
						</td>

						<td>
							<div className='display_flex gray container'>
								<input type='checkbox' id={`id_pure_products_head`} />
								<label
									htmlFor={`id_pure_products_head`}
									onClick={(e) => {
										const parentTable = e.target.closest('table')
										const allInputs = parentTable.querySelectorAll(
											'tbody .container input'
										)
										const input = e.target
											.closest('.container')
											.querySelector('input')

										if (input.checked === true) {
											allInputs.forEach((element) => {
												// Poner filtro
												const product = JSON.parse(element.getAttribute('data-product'))
												// if(product.on_offer === 1){return <></>}
												if(palabra){
													if (
														!String(product.product_code).toLowerCase().includes(palabra.toLowerCase()) &&
														!String(product.description).toLowerCase().includes(palabra.toLowerCase()) &&
														!String(product.category_name).toLowerCase().includes(palabra.toLowerCase()) &&
														!String(product.subcategory_name).toLowerCase().includes(palabra.toLowerCase()) &&
														!String(product.short_brand).toLowerCase().includes(palabra.toLowerCase())
													) {
														return false;
													}
												}
												if(estrategia){
													if(product.strategy_name !== estrategia && estrategia !== 'Todos'){
														return false;
													}
												}
												if(linea){
													if(product.category_name !== linea && linea !== 'Todos'){
														return false;
													}
												}
												if(familia){
													if(product.subcategory_name !== familia && familia !== 'Todos'){
														return false;
													}
												}
												if(catalogo && catalogo !== "Todos"){
													if(product.on_catalog === 1 && catalogo === "No"){
														return false;
													}
													if(product.on_catalog === 0 && catalogo === "Si"){
														return false;
													}
												}
												if(oferta && oferta !== "Todos"){
													if(product.on_offer === 1 && oferta === "No"){
														return false;
													}
													if(product.on_offer === 0 && oferta === "Si"){
														return false;
													}
												}
												element.checked = false
											})
										} else {
											allInputs.forEach((element) => {
												// Poner Filtro
												const product = JSON.parse(element.getAttribute('data-product'))
												// if(product.on_offer === 1){return <></>}
												if(palabra){
													if (
														!String(product.product_code).toLowerCase().includes(palabra.toLowerCase()) &&
														!String(product.description).toLowerCase().includes(palabra.toLowerCase()) &&
														!String(product.category_name).toLowerCase().includes(palabra.toLowerCase()) &&
														!String(product.subcategory_name).toLowerCase().includes(palabra.toLowerCase()) &&
														!String(product.short_brand).toLowerCase().includes(palabra.toLowerCase())
													) {
														return false;
													}
												}
												if(estrategia){
													if(product.strategy_name !== estrategia && estrategia !== 'Todos'){
														return false;
													}
												}
												if(linea){
													if(product.category_name !== linea && linea !== 'Todos'){
														return false;
													}
												}
												if(familia){
													if(product.subcategory_name !== familia && familia !== 'Todos'){
														return false;
													}
												}
												if(catalogo && catalogo !== "Todos"){
													if(product.on_catalog === 1 && catalogo === "No"){
														return false;
													}
													if(product.on_catalog === 0 && catalogo === "Si"){
														return false;
													}
												}
												if(oferta && oferta !== "Todos"){
													if(product.on_offer === 1 && oferta === "No"){
														return false;
													}
													if(product.on_offer === 0 && oferta === "Si"){
														return false;
													}
												}
												element.checked = true
											})
										}
										
									}}
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 24 24'
										fill='#fff'
										className='w-6 h-6'
									>
										<path
											fillRule='evenodd'
											d='M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z'
											clipRule='evenodd'
										/>
									</svg>
								</label>
							</div>
						</td>
					</tr>
				</thead>
				<tbody className='img_saperate_wrapper'>
					{products && products.map((product) => { // .slice(0, 100)
						count++;
						let style_ = true;
						// if(product.on_offer === 1){return <></>}
						if(palabra){
							if (
								!String(product.product_code).toLowerCase().includes(palabra.toLowerCase()) &&
								!String(product.description).toLowerCase().includes(palabra.toLowerCase()) &&
								!String(product.category_name).toLowerCase().includes(palabra.toLowerCase()) &&
								!String(product.subcategory_name).toLowerCase().includes(palabra.toLowerCase()) &&
								!String(product.short_brand).toLowerCase().includes(palabra.toLowerCase())
							) {
								style_ = false;
								// return false;
							}
						}
						if(estrategia){
							if(product.strategy_name !== estrategia && estrategia !== 'Todos'){
								style_ = false;
								// return false;
							}
						}
						if(linea){
							if(product.category_name !== linea && linea !== 'Todos'){
								style_ = false;
								// return false;
							}
						}
						if(familia){
							if(product.subcategory_name !== familia && familia !== 'Todos'){
								style_ = false;
								// return false;
							}
						}
						if(catalogo && catalogo !== "Todos"){
							if(product.on_catalog === 1 && catalogo === "No"){
								style_ = false;
								// return false;
							}
							if(product.on_catalog === 0 && catalogo === "Si"){
								style_ = false;
								// return false;
							}
						}
						if(oferta && oferta !== "Todos"){
							if(product.on_offer === 1 && oferta === "No"){
								style_ = false;
								// return false;
							}
							if(product.on_offer === 0 && oferta === "Si"){
								style_ = false;
								// return false;
							}
						}
						return (
							<PureTableProductsRow name='6J000' 
							dataProduct = {product} key={"key_"+product.offer_product_id}
							style={style_}
							/>
						)
					})
					}
					
				</tbody>
			</Table>
		</div>
	)
}
