import React from "react";
import Table from "react-bootstrap/Table";
import moment from 'moment'
import 'moment/locale/es'

import Jumbo from "../../assets/img/promorec.svg";
import {config, getImgCustomer} from "../../config"

export const NombreTable = ({
  nombrePromocion,
  nombreCliente,
  promotion
}) => {
  moment.locale('es')
  console.log("Data Promocional Resumen:::: ")
  console.log(promotion)
  return (
		<div className='NombreTable NombreTableNew'>
			<Table>
				<tbody>
					<tr>
						<td>
							<p>Nombre</p>
							<h1>{
							//data.cadena_details.name_promotion
							promotion.offer_data.promotion_name
							}</h1>
						</td>
						<td>
							<p>Oficinas</p>
							{promotion.offer_data.offices}
						</td>
						<td>
							<p>Fecha</p>
							<h1>
								{promotion.offer_data.month} {promotion.offer_data.end_sellin.split("/")[2]}
							</h1>
						</td>
						<td>
							<p>Fase</p>
							<h1 className='badge_blue'>
								{promotion.offer_data.phase}
							</h1>
						</td>
						<td>
							<p>Estado</p>
							<h1 className='badge_blue'>
								{promotion.offer_data.state}
							</h1>
						</td>
					</tr>
				</tbody>
			</Table>

			<Table>
				<tbody>
					<td>
						<p>Vigencia</p>

						<p>
							In.{' '}
							<b>
								{ /*moment(promotion.start_sellin)
									.format('DD/MMM/YYYY')
									.replace(/\./g, '')
								*/
								promotion.offer_data.start_sellin
								}
							</b>{' '}
							<br />
							Te.{' '}
							<b>
								{ /*moment(promotion.end_sellin)
									.format('DD/MMM/YYYY')
							.replace(/\./g, '')*/ 
							promotion.offer_data.end_sellin
							}
							</b>
						</p>
					</td>
					
					<td>
						<p>Número SKU</p>

						<p>
							{promotion.data_rows.length}
						</p>
					</td>

					<td>
						<p>Número Act.</p>

						<p>
							<b>{promotion.data_rows.length}</b>
						</p>
					</td>

					<td>
						<p>Volumen Estr.</p>

						<p>
							<b>{promotion.header[0].strat_vol}</b>
						</p>
					</td>
				</tbody>
			</Table>
		</div>
	)
};
