import React from 'react'
import '../../assets/css/newPFOffer/delete.css'
import bin from '../../assets/img/newPFOffer/bin.svg'
import { config } from '../../config'

export const DeleteRow = ({setActive, setdeletepossiblestate, deleteRow, setLoading, setOffers, idOferta}) => {
	const executeDeleteRow = async () => {
		//Guardar INFO
		console.log("DATA PRODUCTO A ELIMINAR TRAD")
		console.log(deleteRow)
		setLoading(true);
		const session_token = localStorage.getItem('session');
		const response = await fetch(config.apiUrlTradOferta + `/offer/product/delete/`+idOferta+`/`+deleteRow.key, {
		  //mode: 'no-cors',
			headers: {
			  'Content-Type': 'application/json',
			  'Authorization': 'Bearer ' + session_token,
			  "Access-Control-Allow-Origin" : "*", 
			  "Access-Control-Allow-Credentials" : true
			},
			method: "POST",
		  });
		  const responseJSON = await response.json();
		  setOffers(responseJSON.offer_data);
		  console.log("RESPUESTA SERVICIO SIMULACION");
		  console.log(responseJSON);
		  // window.location.reload();
		  // Actualizar tabla principal luego de haber agregado un producto
		  console.log("Actualizando productos...");
		  // alert("Actualizando productos......")
		  setLoading(false);
		  // window.location.reload();
		}
	return (
		<div className='popupWrapper DeleteRow'>
			<span
				className='DeleteRowbg'
				onClick={(e) => {
					setActive(false)
				}}
			></span>
			<div className='popup promocien'>
				<img src={bin} alt='' />
				<h1>¿Estás seguro que deseas eliminar el producto {deleteRow.product_rows[0].product_code}? </h1>
				<p>(Se actualizará la página actual para la consistencia de los cálculos agrupados recomendamos guardar antes de realizar esta acción) Podrás volver a ingresarlo cuando quieras.</p>
				<div className='buttons-wrapper'>
					<button
						onClick={(e) => {
							setActive(false)
						}}
					>
						Seguir Editando
					</button>
					<button
						className='outline'
						onClick={(e) => {
							executeDeleteRow()
							setActive(false)
							// setdeletepossiblestate(true)
						}}
					>
						Eliminar Producto
					</button>
				</div>
			</div>
		</div>
	)
}
