import React from 'react'
import moment from 'moment'
import 'moment/locale/es'


import Jumbo from '../assets/img/Jumbo.svg'
import img1 from '../assets/img/Mayorista.png'
import img2 from '../assets/img/Unimarc.png'
import Lider from '../assets/img/Lider.png'
import Santa from '../assets/img/Santa Isabel.svg'
import Tottus from '../assets/img/Tottus.svg'
import central_mayorista from '../assets/img/central_mayorista.png'
import acuenta from '../assets/img/Acuenta.png'
import ekono from '../assets/img/Ekono.png'

export const ActiviyBox = ({
	topText,
	mainp,
	bottomp1,
	bottomp2,
	img,
	orange = null,
	black = null,
	Offerta = null,
	catalogo = null,
	jumbo = null,
	tutto = null,
	bgColor = null,
	percentage = null,
	date = null,
	setActivityactive,
	Activityactive,
	date_created = null,
	linea,
}) => {
  moment.locale('es')
	let imagen_cadena = null
	let imagen_ = true
	switch (img) {
		case 'SANTA ISABEL':
			imagen_cadena = <img src={{ Santa }.Santa} />
			break
		case 'JUMBO':
			imagen_cadena = <img src={{ Jumbo }.Jumbo} />
			break
		case 'UNIMARC':
			imagen_cadena = <img src={{ img2 }.img2} />
			break
		case 'LIDER':
			imagen_cadena = <img src={{ Lider }.Lider} />
			break
		case 'MAYORISTA 10':
			imagen_cadena = <img src={{ img1 }.img1} />
			break
		case 'TOTTUS S.A.':
			imagen_cadena = <img src={{ Tottus }.Tottus} />
			break
		case 'ACUENTA':
			imagen_cadena = <img src={{ acuenta }.acuenta} />
			break
		case 'EKONO':
			imagen_cadena = <img src={{ ekono }.ekono} />
			break
		case 'LIDER MAYORISTA':
			imagen_cadena = <img src={{ central_mayorista }.central_mayorista} />
			break
		default:
			imagen_ = false
			imagen_cadena = img.toLowerCase()
			if (imagen_cadena.includes(",")) {
				// Si contiene ",", se hace un split, se cuenta la cantidad de elementos y se genera la cadena deseada
				const elementos = imagen_cadena.split(",");
				imagen_cadena = `${elementos.length} oficinas`;
			} else {
				// Si no contiene ",", se asegura que la cadena tenga exactamente 10 caracteres
				imagen_cadena = imagen_cadena.substring(0, 12); // Relleno con espacios si es necesario
			}
			break
	}

	let bgColor_final = 'FFAC24'
	let percentage_ = percentage.split('%')[0].replace(',', '.')
	if (
		parseFloat(percentage_) >= 0 &&
		parseFloat(percentage_).toFixed(2) <= 4.45
	) {
		bgColor_final = '#FF5334'
	}
	if (
		parseFloat(percentage_) >= 4.5 &&
		parseFloat(percentage_).toFixed(2) <= 14.45
	) {
		bgColor_final = '#FFAC23'
	}
	if (
		parseFloat(percentage_) >= 14.5 &&
		parseFloat(percentage_).toFixed(2) <= 29.45
	) {
		bgColor_final = '#13AC7E'
	}
	if (parseFloat(percentage_) >= 29.5) {
		bgColor_final = '#36A7F5'
	}

	return (
		<div
			className='ActiviyBox'
			onClick={(e) => {
				setActivityactive(Activityactive) //Acá se settea lo que va al modal
			}}
		>
			<div className='top_area'>
				<p className={`badge ${orange && 'orange'} ${black && 'black'}`}>
					{topText}
				</p>
				<span>{moment(date_created.split(" ")[0]).format('DD/MMM/YYYY').replace(/\./g, '')}</span>
				<div className='percentage'>
					<span style={{ backgroundColor: bgColor_final }}>
						{String(
							parseFloat(percentage.split('%')[0].replace(',', '.')).toFixed(1)
						).replace('.', ',')}
						%
					</span>
				</div>
			</div>
			<p>{mainp}</p>
			<div className='bottom_area'>
				<p>{date.replace(' ', '.')}</p>
				<p
					style={{ cursor: 'pointer' }}
					onClick={(e) => {
						if (bottomp1 == 'OFERTA') {
							Offerta()
						}
						if (bottomp1 == 'CATÁLOGO') {
							catalogo()
						}
					}}
				>
					{bottomp1}
				</p>
				<p
					style={{ cursor: 'pointer' }}
					onClick={(e) => {
						if (bottomp2 == 'OFERTA') {
							Offerta()
						}
						if (bottomp2 == 'CATÁLOGO') {
							catalogo()
						}
					}}
				>
					{bottomp2}
				</p>

				{bottomp2 == 'TRADICIONAL' ? (
					<>
						<p
							style={{ cursor: 'pointer' }}
							onClick={(e) => {
								if (bottomp1 == 'OFERTA') {
									Offerta()
								}
								if (bottomp1 == 'CATÁLOGO') {
									catalogo()
								}
							}}
						>
							{Activityactive.linea}
						</p>
					</>
				) : (
					<>
						<div
							className='img_wrapper'
							style={{ cursor: 'pointer', fontSize: '10px' }}
							onClick={(e) => {
								if (jumbo) {
									jumbo()
								}
								if (tutto) {
									tutto()
								}
							}}
						>
							{imagen_cadena}
						</div>
					</>
				)}
			</div>
		</div>
	)
}
