import React, { useState, useEffect, ReactDOM, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/es'

import { EliminarShow } from './EliminarShow'
import { Mover } from './Mover'
import { config, getImgCustomer } from '../config'

import bookmark from '../assets/img/bookmark.svg'
import AvatarPop from '../assets/img/AvatarPop.svg'
import Actividad from '../assets/img/Actividad.svg'
import Badge from 'react-bootstrap/Badge'
import Resumen from '../assets/img/Icono Resumen.svg'
import Cerrar from '../assets/img/Cerrar.svg'
import Avatargirl from '../assets/img/Avatargirl.svg'
import { set } from 'date-fns'

//import ReactDOMServer from 'react-dom/server';

function getComments(promotion_id) {
	const session_token = localStorage.getItem('session')
	//console.log(session_token);
	//if there is a session token stored
	if (session_token) {
		return fetch(
			config.apiUrl + `/comment_pb/activity/` + promotion_id,
			{
				//mode: 'no-cors',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + session_token,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
			}
		)
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}
	return {
		//return empty json
	}
}

function getCommentsOffer(promotion_id) {
	const session_token = localStorage.getItem('session')
	//console.log(session_token);
	//if there is a session token stored
	if (session_token) {
		return fetch(
			config.apiUrlOferta + `/offer/activity/` + promotion_id,
			{
				//mode: 'no-cors',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + session_token,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
			}
		)
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}
	return {
		//return empty json
	}
}


export const JumboPopNew = ({ setActive, Activityactive }) => {
	moment.locale('es')
	const navigate = useNavigate()
	const [color, setColor] = useState('')
	const [eliminarShow, seteliminarShow] = useState(false)
	const [moverShow, setmoverShow] = useState(false)
	const [dataComments, setDataComments] = useState(false)
	const [flag, setFlag] = useState(false)

	let oficinas = ''
	let linea = Activityactive.linea
	let fecha_str =
		Activityactive.month_promotion_str + ' ' + Activityactive.year_promotion_str
	if (Activityactive.customer.name_customer.toUpperCase() === 'TRADICIONAL') {
		oficinas = String(Activityactive.offices.replace('\\', ''))
			.toLowerCase()
			.replace('"', '')
			.replace('","', ',')
			.replace('"', '')
	}

	useEffect(() => {
		let Percentage = String(Activityactive.percent_activation)
			.split('%')[0]
			.replace(',', '.') //Activityactive["percentage"].split("%")[0];
		if (
			parseFloat(Percentage) >= 0 &&
			parseFloat(Percentage).toFixed(2) <= 4.45
		) {
			setColor('#FF5334')
		}
		if (
			parseFloat(Percentage) >= 4.5 &&
			parseFloat(Percentage).toFixed(2) <= 14.45
		) {
			setColor('#FFAC23')
		}
		if (
			parseFloat(Percentage) >= 14.5 &&
			parseFloat(Percentage).toFixed(2) <= 29.45
		) {
			setColor('#13ACAA')
		}
		if (parseFloat(Percentage) >= 29.5) {
			setColor('#36A7F5')
		}
	}, [])

	const refCatalogModal = useRef(null)
	useEffect(() => {
		document.addEventListener('click', closeModal, true)
	}, [])

	const closeModal = (e) => {
		if (!refCatalogModal.current.contains(e.target)) {
			setActive(false)
		}
	}

	useEffect(() => {
		//setData({promotions: window.catalog_categories})
		if(Activityactive.type_promotion.type_promotion === "Catalogo"){
			getComments(Activityactive.id).then((result) => {
				console.log('##########comentarios:::: ')
				console.log(result)
				setDataComments(result)
				setFlag(false)
			})
		}else{
			console.log("Se llama a oferta comentarios...")
			getCommentsOffer(Activityactive.promotion_id).then((result) => {
				console.log('##########comentarios:::: ')
				console.log(result)
				setDataComments(result)
				setFlag(false)
			})
		}
	}, [flag])

	const Callback = async (promotion_id) => {
		const comment = document.getElementById('input_comment').value
		//alert(comment)

		const session_token = localStorage.getItem('session')
		if (session_token) {
			const response = await fetch(config.apiUrl + `/comment_pb/create`, {
				//mode: 'no-cors',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + session_token,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
				body: JSON.stringify([
					{
						id_promotion: promotion_id,
						comment_text: comment,
						send_notification: 1,
					},
				]),
			})
			const responseJSON = await response.json()
			document.getElementById('input_comment').value = ''
			// setDataComments(dataComments.concat(responseJSON))
			document.getElementById('input_comment').disabled = false
			setFlag(true)
		}
	}

	const CallbackOffer = async (promotion_id) => {
		const comment = document.getElementById('input_comment').value
		//alert(comment)

		const session_token = localStorage.getItem('session')
		if (session_token) {
			const response = await fetch(config.apiUrlOferta + `/comment/create`, {
				//mode: 'no-cors',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + session_token,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
				body: JSON.stringify(
					{
						promotion_id: promotion_id,
						comment_text: comment					},
				),
			})
			const responseJSON = await response.json()
			document.getElementById('input_comment').value = ''
			// setDataComments(dataComments.concat(responseJSON))
			document.getElementById('input_comment').disabled = false
			setFlag(true)
		}
	}

	const onEnter = (event, promotion_id) => {
		if (event.key === 'Enter') {
			document.getElementById('input_comment').disabled = true
			if(Activityactive.type_promotion.type_promotion === "Catalogo"){
				Callback(promotion_id)
			}else{
				CallbackOffer(Activityactive.promotion_id)
			}
		}
	}

	const getStrMonth3 = (month_str) => {
		const montsh_obj = {
			Jan: '01',
			Feb: '02',
			Mar: '03',
			Apr: '04',
			May: '05',
			Jun: '06',
			Jul: '07',
			Aug: '08',
			Sep: '09',
			Oct: '10',
			Nov: '11',
			Dec: '12',
		}
		return montsh_obj[month_str]
	}

	const deleteComment = async (comment_id) => {
		const session_token = localStorage.getItem('session')
		const response = await fetch(config.apiUrl + `/comment_pb/delete`, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
			method: 'POST',
			body: JSON.stringify([
				{
					id: comment_id,
				},
			]),
		})
		const responseJSON = await response.json()
		document.getElementById('box_comment_' + comment_id).outerHTML = ''
		console.log('Respuesta Comentario Eliminado:::')
		console.log(responseJSON)
	}

	return (
		<div className='popupWrapper OfertaWrapper JumboPopNewVersion TottusPop TottusPopNew'>
			<div className='popup' ref={refCatalogModal}>
				{eliminarShow && (
					<EliminarShow
						seteliminarShow={seteliminarShow}
						promotionId={Activityactive.id}
						data={Activityactive}
					/>
				)}
				{moverShow && (
					<Mover setmoverShow={setmoverShow} data={Activityactive} />
				)}

				<div className='close_icon' onClick={(e) => setActive(false)}>
					<img src={Cerrar} alt='' />
				</div>
				<div className='left_side'>
					<h1>
						<img src={bookmark} alt='' />
						{Activityactive.name_promotion}
					</h1>
					<div className='first_left'>
						<div className='left_box'>
							<h1>Fase</h1>
							<p>{Activityactive.promotional_state.phase_str}</p>
						</div>
						<div className='left_box'>
							<h1>Estado</h1>
							<p>
								{Activityactive.promotional_state.state_phase_str.toUpperCase()}
							</p>
						</div>
						<div className='left_box right_most_area '>
							{Activityactive.customer.name_customer.toUpperCase() ===
							'TRADICIONAL' ? (
								<>
									<h1>Oficinas</h1>
									{/*
              <img src={null//Activityactive["img"]
              } alt="" />
            */}

									<span style={{ fontSize: '10px' }}>
										{String(Activityactive.offices.replace('\\', ''))
											.replace('"', '')
											.replace('","', ',')
											.replace('"', '')}
									</span>
								</>
							) : (
								<>
									<h1>Cadena</h1>
									{/*
              <img src={null//Activityactive["img"]
              } alt="" />
            */}
									{getImgCustomer(
										Activityactive.customer.name_customer.toUpperCase(),
										100
									)}
								</>
							)}
						</div>
					</div>
					<h1 style={{ marginTop: 30 }}>
						<img src={Resumen} alt='' />
						Resumen
					</h1>
					<div
						className='badge_long'
						style={{ gridTemplateColumns: '.7fr .7fr 1fr 1fr .7fr' }}
					>
						<div className='box_inner'>
							<h1>Canal</h1>
							<p>{Activityactive.customer.channel.description_channel}</p>
						</div>
						<div className='box_inner'>
							<h1>Modalidad</h1>
							<p>{Activityactive.type_promotion.type_promotion}</p>
						</div>
						<div className='box_inner'>
							{Activityactive.customer.channel.description_channel.toUpperCase() ===
							'TRADICIONAL' ? (
								<h1>Fecha Vigencia</h1>
							) : (
								<h1>Sell-In</h1>
							)}

							<p>
								{moment(Activityactive.start_sellin)
									.format('DD/MMM/YYYY')
									.replace(/\./g, '')}{' '}
								{moment(Activityactive.end_sellin)
									.format('DD/MMM/YYYY')
									.replace(/\./g, '')}
							</p>
						</div>
						<div className='box_inner'>
							{Activityactive.customer.channel.description_channel.toUpperCase() ===
							'TRADICIONAL' ? (
								<>
									<h1>Línea</h1>
									<p>{Activityactive.linea}</p>
								</>
							) : (
								<>
									<h1>Sell-Out</h1>
									<p>
										{moment(Activityactive.start_sellout)
											.format('DD/MMM/YYYY')
											.replace(/\./g, '')}{' '}
										{moment(Activityactive.end_sellout)
											.format('DD/MMM/YYYY')
											.replace(/\./g, '')}
									</p>
								</>
							)}
						</div>
						<div className='box_inner'>
							<h1>Fecha</h1>
							<p>
								{Activityactive.month_promotion_str.slice(0, 3)} -{' '}
								{Activityactive.year_promotion_str}
							</p>
						</div>

						<div className='box_inner'>
							<h1>Beneficio Opt.</h1>
							<p>${String(Activityactive.ben_opt).split(',')[0]}</p>
						</div>
						<div className='box_inner'>
							<h1>Beneficio Prop.</h1>
							<p>${String(Activityactive.ben_prop).split(',')[0]}</p>
						</div>
						<div className='box_inner'>
							<h1>Res. Op. Opt.</h1>
							<p>${String(Activityactive.res_op_opt).split(',')[0]}</p>
						</div>
						<div className='box_inner'>
							<h1>Res. Op. Prop.</h1>
							<p>${String(Activityactive.res_op_prop).split(',')[0]}</p>
						</div>
						<div className='box_inner'>
							<h1>Actv.</h1>
							<p>
								<Badge className='badgeboot' style={{ background: color }}>
									{String(
										parseFloat(
											String(Activityactive.percent_activation).replace(',', '.')
										).toFixed(1)
									).replace('.', ',')}
									%
								</Badge>
							</p>
						</div>
					</div>

					<h1 style={{ marginTop: 20 }}>
						<img src={Actividad} alt='' />
						Actividad
					</h1>

					<div className='profile_box_wrapper'>
						<div className='profile_box main_profile_box'>
							<img src={Avatargirl} alt='' />
							<div className='presentation'>
								<input
									type='text'
									placeholder='Escribe un comentario...'
									onKeyPress={(e) => onEnter(e, Activityactive.id)}
									id='input_comment'
									autocomplete='off'
								/>
							</div>
						</div>
						<div id='section_comments' className='commentsMax'>
							{!dataComments
								? 'Cargando Comentarios ...'
								: dataComments.sort((a,b) => {return b.id_comment - a.id_comment})
								.map((EachRow) =>
										EachRow.comment_text.includes('@') ? (
											<div
												className='profile_box'
												id={'box_comment_' + EachRow.id_comment}
												style={{ marginBottom: '10px' }}
											>
												<img src={AvatarPop} alt='' />
												<div className='presentation'>
													<div className='top_area'>
														<h1>
															{EachRow.name_user} {EachRow.last_name_user}
														</h1>
													</div>
													<div className='middle_area'>
														<p>
															{EachRow.comment_text}{' '}
															<span>
																{EachRow.promotional_state.state_phase_str}
															</span>
														</p>
													</div>
													<p>
														{EachRow.date_created}
														{
														/*
														String(EachRow.date_created).split(' ')[1] +
															'/' +
															getStrMonth3(
																String(EachRow.date_created).split(' ')[2]
															) +
															'/' +
															String(EachRow.date_created).split(' ')[3]}{' '}
														a las{' '}
														{String(EachRow.date_created)
															.split(' ')[4]
															.split(':')[0] +
															':' +
															String(EachRow.date_created)
																.split(' ')[4]
																.split(':')[1]}
														*/}
														{/*<span
                        onClick={(e) => {
                          deleteComment(EachRow.id_comment)
                        }}
                        style={{marginRight:"0px"}}
                      >Eliminar</span>
                      */}
													</p>
												</div>
											</div>
										) : (
											<div className='profile_box responder'>
												<img src={AvatarPop} alt='' />
												<div className='presentation'>
													<div className='top_area'>
														<h1>
															{EachRow.name_user} {EachRow.last_name_user}
														</h1>
														<p>
														{EachRow.date_created}
														</p>
													</div>
													<input
														type='text'
														placeholder={EachRow.comment_text}
														value={EachRow.comment_text}
													/>
												</div>
											</div>
										)
								  )}
						</div>

						{/*
            <div className="profile_box responder">
              <img src={Avatargirl} alt="" />
              <div className="presentation">
                <div className="top_area">
                  <h1>María M.</h1>
                  <p>hace 10 mínutos</p>
                </div>
                <input type="text" placeholder="Saquemos el Jamón Ahumado" />
                <button>Responder</button>
              </div>
            </div>

            <div className="profile_box">
              <img src={AvatarPop} alt="" />
              <div className="presentation">
                <div className="top_area">
                  <h1>Juan Martínez</h1>
                  <p>ha movido esta promoción al estado</p>
                  <span>EN EDICIÓN</span>
                </div>
                <p>31 / 01 / 2022 a las 14:25</p>
              </div>
            </div>
            <div className="profile_box">
              <img src={AvatarPop} alt="" />
              <div className="presentation">
                <div className="top_area">
                  <h1>Pedro Perez</h1>
                  <p>ha movido esta promoción al estado</p>
                </div>
                <p>31 / 01 / 2022 a las 14:25</p>
              </div>
            </div>
            <div className="profile_box">
              <img src={AvatarPop} alt="" />
              <div className="presentation">
                <div className="top_area">
                  <h1>María M.</h1>
                  <p>ha movido esta promoción al estado</p>
                </div>
                <p>31 / 01 / 2022 a las 14:25</p>
              </div>
            </div>
*/}
					</div>
				</div>
				<div className='right_side'>
					<div className='buttons_right_side'>
						<h1>Promoción</h1>
						{Activityactive.customer.name_customer.toUpperCase() !=
						'TRADICIONAL' ? ( // Acá se diferencia solo en tradicional y moderno
							<>
								<button
									className='active'
									onClick={() => {
										//window.location.href="/CataLogoResumen/"+Activityactive.id}
										if(Activityactive.type_promotion.type_promotion === "Catalogo"){
											return navigate('/CataLogoResumen/' + Activityactive.id, {
												catalogo_data: null,
												catalog_categories: null,
												inserted_promotion_id: Activityactive.id,
												promotion_id: Activityactive.id,
											})
										}else{
											console.log("Tipoop::::")
											console.log(Activityactive.type_promotion.description_channel)
											if(Activityactive.customer.channel.description_channel === "Tradicional"){
												return navigate('/IngresoProductTrad/' + Activityactive.promotion_id, {
													catalogo_data: null,
													catalog_categories: null,
													inserted_promotion_id: Activityactive.id,
													promotion_id: Activityactive.id,
												})
											}else{
												return navigate('/IngresoProduct/' + Activityactive.promotion_id, {
													catalogo_data: null,
													catalog_categories: null,
													inserted_promotion_id: Activityactive.id,
													promotion_id: Activityactive.id,
												})
											}
											
										}
										
									}}
									// disabled={Activityactive.promotional_state.state_phase_str.toUpperCase() != 'DESCARTADA'}
								>
									Editar
								</button>

								<button
									className='active'
									onClick={() => {
										if(Activityactive.type_promotion.type_promotion === "Catalogo"){
											window.location.href =
												'/CataLogoResumen/' + Activityactive.id
										}else{
											if(Activityactive.customer.channel.description_channel === "Tradicional"){
												window.location.href =
													'/IngresoProductTrad/' + Activityactive.promotion_id
											}else{
												window.location.href =
													'/IngresoProduct/' + Activityactive.promotion_id
											}
										}
									}}
								>
									Visualizar
								</button>
							</>
						) : (
							<>
								<button
									className='active'
									onClick={() => {
										//window.location.href="/CataLogoResumen/"+Activityactive.id}
										if(Activityactive.type_promotion.type_promotion === "Catalogo"){
											return navigate(
												'/CatalogoTradicional/' +
													Activityactive.id +
													'/' +
													Activityactive.name_promotion +
													'/' +
													oficinas +
													'/' +
													fecha_str +
													'/' +
													linea,
												{
													catalogo_data: null,
													catalog_categories: null,
													inserted_promotion_id: Activityactive.id,
													promotion_id: Activityactive.id,
												}
											)
										}else{

											
											return navigate('/IngresoProductTrad/' + Activityactive.promotion_id, {
												catalogo_data: null,
												catalog_categories: null,
												inserted_promotion_id: Activityactive.id,
												promotion_id: Activityactive.id,
											})
										}
										
									}}
								>
									Editar
								</button>

								<button
									className='active'
									onClick={() => {
										if(Activityactive.type_promotion.type_promotion === "Catalogo"){
											window.location.href =
												'/CatalogoTradicional/' +
												Activityactive.id +
												'/' +
												Activityactive.name_promotion +
												'/' +
												oficinas +
												'/' +
												fecha_str +
												'/' +
												linea
										}else{
											window.location.href =
												'/IngresoProductTrad/' +
												Activityactive.id
										}
									}}
								>
									Visualizar
								</button>
							</>
						)}
					</div>
					<div className='buttons_right_side'>
						<h1>Fase y Estado</h1>
						<button
							className='active'
							onClick={(e) => {
								setmoverShow(true)
							}}
						>
							Mover
						</button>
					</div>
					<div className='buttons_right_side'>
						<h1>Acciones</h1>
						<button
							className='active'
							onClick={(e) => {
								seteliminarShow(true)
							}}
						>
							Eliminar
						</button>
						<button className=''>Duplicar</button>
						{
							//Para activar el boton, poner en className "active"
						}
					</div>
				</div>
			</div>
		</div>
	)
}
