import React, {useEffect, useState} from 'react'
import '../../assets/css/newPFOffer/IngresoProductTable.css'
import dropdownprod from '../../assets/img/newPFOffer/dropdown.svg'
import tick2 from '../../assets/img/newPFOffer/tick.svg'
import dollar from '../../assets/img/dollar.svg'
import greenarrow from '../../assets/img/newPFOffer/green-arrow.svg'
import orangearrow from '../../assets/img/orange_down.svg'
import closing from '../../assets/img/newPFOffer/close.svg'
import {ProductTableInner} from './ProductTableInner'
import {ProductTableInnerTradicional} from './ProductTableInnerTradicional'
import { ToolTipCustomList } from '../ToolTipCustomList'
import Recomendacion2 from '../../assets/img/Recomendacion2.svg'
import Volumen from '../../assets/img/Volumen.svg'
import Volumen_Rentabilidad from '../../assets/img/Volumen_Rentabilidad.svg'
import Recomendacion from '../../assets/img/Recomendacion.svg'
import Si from '../../assets/img/Si.svg'
import No from '../../assets/img/No.svg'
import {formatNumberES, redondearDecena} from '../../config'
import { set } from 'date-fns'
import {PromotionalVariableOffer} from './PromotionalVariableOffer'

function contarOcurrencias(jsonData) {
	let vacias = 0;
	let noVacias = 0;
  
	for (const key in jsonData) {
	  if (jsonData.hasOwnProperty(key)) {
		const valor = jsonData[key];
  
		if (valor === "" || valor === "-" || valor === "0") {
		  vacias++;
		} else {
		  noVacias++;
		}
	  }
	}
  
	return { vacias, noVacias };
  }

export const ProductTableFamiliaInner = ({
	// ProductTableInnerState,
	// setProductTableInnerState,
	setdeletestate,
	setModeloPopupState,
	offer, setProduct, setRowChange, setDeleteRow
}) => {
	console.log("Offer data::::::::::::::: ")
	console.log(offer)
	const [estr, setestr] = useState(formatNumberES(offer.strat_price.replace("$","").replace(".","").replace(",",".")))
	const [dcto, setdcto] = useState(0); //useState(offer.discount.replace("%","").replace(",","."))
	const [mgcadb, setmgcadb] = useState(0)// useState(parseFloat(offer.customer_margin.replace("%","").replace(",",".")).toFixed(1))
	const [recommended_pvp, setrecommended_pvp] = useState(formatNumberES(offer.recommended_pvp.replace("$","").replace(".","").replace(",",".")))
	const [ProductTableInnerState, setProductTableInnerState] = useState(null)
	const [MouseDownState, setMouseDownState] = useState(null)
	const [onEdit, setOnEdit] = useState(false)
	const [PromotionalVariable, setPromotionalVariable] = useState(false)
	const resultado = contarOcurrencias(offer.promotional_variables_json);
	const [offerRow, setOfferRow] = useState(offer)
	const [activaciones, setActivaciones] = useState(offer.product_rows.length)
	const [activadas, setActivadas] = useState(offer.product_rows.filter((office) => office.active_office === 1).length)

	// let estr = String(offer.base_price).replace(".","").replace(",",".").replace("$","") * (1 - (parseInt(e.target.value)/100))
	// let desct = parseFloat(1 - (parseInt(e.target.value)/parseInt(String(offer.base_price).replace(".","").replace(",",".").replace("$",""))), 1) * 100

	const rowChangeFunctionDesc = () => {
		// setProductTableInnerState(!ProductTableInnerState)
		let row = JSON.parse(JSON.stringify(offerRow));
		row.strat_price = estr
		row.__strat_price = parseInt(estr.replace(".","").replace(",",".").replace("$",""))

		row.product_rows.map((office) => {
			if(office.active_office === 1){
				office.strat_price = "$"+formatNumberES(estr)
				office.__strat_price = parseInt(estr.replace(".","").replace(",",".").replace("$",""))
				office.strat_price_modified = 1
				// document.getElementById("office_"+office.id).value = "$"+formatNumberES(estr)
				// console.log("office_"+office.id+"   cambiado...."+formatNumberES(estr))
			}
		})

		row.strat_price_modified = 1

		setOfferRow(row)
		setRowChange(row)
		
	}

	const rowChangeFunctionDescOffice = (id_office, precio) => {
		console.log("Activando Oficina con cambio de precio....")
		let row = JSON.parse(JSON.stringify(offerRow));
		// alert(id_office+" "+estado+" "+offer_product_id)
		
				/// office.active_office = estado
		row.product_rows.map((office) => {
					if(office.id === id_office){
						office.active_office = 1
						// offer.active_offices = offer.product_rows.filter((office) => office.active_office === true).length
						office.strat_price = "$"+formatNumberES(precio)
						office.__strat_price = parseInt(estr.replace(".","").replace(",",".").replace("$",""))
						office.strat_price_modified = 1
					}
		})
		row.active_offices = row.product_rows.filter((office) => office.active_office === 1).length;
		row.strat_price_modified = 1
		console.log("Data a simular despues de cambiar oficinas:::")
		setOfferRow(row)
		setRowChange(row)
		setActivadas(row.product_rows.filter((office) => office.active_office === 1).length)
		
	}

	useEffect(() => {
		console.log("Renderizacion");
	}, [offerRow])

	const activateOffice = (id_office, estado, offer_product_id) => { // id
		console.log("Activando Oficina....")
		let row = JSON.parse(JSON.stringify(offerRow));
		// alert(id_office+" "+estado+" "+offer_product_id)
		
				/// office.active_office = estado
		row.product_rows.map((office) => {
					if(office.id === id_office){
						office.active_office = estado
						// offer.active_offices = offer.product_rows.filter((office) => office.active_office === true).length
						office.strat_price = "$"+formatNumberES(estr)
						office.__strat_price = parseInt(estr.replace(".","").replace(",",".").replace("$",""))
					}
		})
		row.active_offices = row.product_rows.filter((office) => office.active_office === 1).length;
		console.log("Data a simular despues de cambiar oficinas:::")
		setOfferRow(row)
		setRowChange(row)
		setActivadas(row.product_rows.filter((office) => office.active_office === 1).length)
	}

	return (
		
			<>
			{PromotionalVariable && <PromotionalVariableOffer 
				setPromotionalVariable={setPromotionalVariable}
				data={offer}
				setData={setRowChange}
				dataPromotionals={offer}
				
			/>}
			<tr className='ProductTableInner familia1 !h-[30px]'>{ /* activate familia1 */  }
				<td>
					<div className='display_flex gray' style={{cursor: 'pointer', position: 'relative'}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					<ToolTipCustomList 
					// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
					list={[offer.product_rows[0].strategy_name]}
					width={'80px'} 
					showOption='show'
					/>
						{/*model, si es 1 es el check y si es 0 es la x*/}
					{offer.model ? <img src={Si} alt='' /> : <img src={No} alt='' />}
					{/* si es 0 entonces ciruclo, */}

					{(offer.product_rows[0].strategy_name === "" || !offer.product_rows[0].strategy_name) 
					&& 
					<img src={Recomendacion} alt='' />}
					{offer.product_rows[0].strategy_name === "Rentabilidad" && 
					
					<img src={Recomendacion2} alt='' />}
					{offer.product_rows[0].strategy_name === "Volumen" && 
					
					<img src={Volumen} alt='' />}
					{offer.product_rows[0].strategy_name === "Optimizacion" && (
					
					<img src={Volumen_Rentabilidad} alt='' />
					)}
					
					.
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<a
							href='#'
							onClick={(e) => {
								setProduct(offer)
								setModeloPopupState(true)
							}}
						>
							{offer.product_rows[0].product_code}
						</a>
					</div>
				</td>
				<td className='description'>
					<div className='display_flex  gray'>
						<p>{offer.product_description}</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<p>{offer.subfamily}</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p>{offer.product_rows[0].short_brand}</p>
						
					</div>
				</td>
				<td className=''>
					<div className='display_flex gray'>
					<img
							src={dropdownprod}
							alt=''
							style={{cursor: 'pointer'}}
							onClick={(e) => {
								setProductTableInnerState(!ProductTableInnerState)
							}}
						/>
						<p className='ele'>{activadas} Act.</p>
					</div>
				</td>
				<td className='border-gray-custom-blue'>
					<div className='display_flex gray'>
						
						<p>{activadas} / {activaciones}</p>
					</div>
				</td>
				<td>
					<div className='display_flex  gray'>
						
						<p className='ele'>{offer.curr_vol}</p>
						
					</div>
				</td>

				<td>
					<div className='display_flex gray' style={{cursor: 'pointer', position: 'relative',
					fontSize: '11px'}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					<ToolTipCustomList 
					// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
					list={[offer.product_rows[0].tooltip_strat_vol_kg]}
					width={'80px'} 
					showOption='show'
					/>
						<p className='ele'>{offer.opt_vol}</p>
						
					</div>
				</td>
				<td className='border-gray-custom-blue'>
					<div className={`display_flex  ${parseFloat(String(offer.strat_vol).split("%")[0].replace(",", ".")) > 0 ? 'green' : 'orange'}`} style={{cursor: 'pointer', position: 'relative',
					fontSize: '11px'}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					
					
						{parseFloat(String(offer.strat_vol).replace("%","").replace(",",".")) > 0 ? 
						<img src={greenarrow} alt='' />
						:
						<img src={orangearrow} alt='' />}
						<p>
						<ToolTipCustomList 
							// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
							list={[
								String(
								parseFloat(
								parseFloat(String(offer.strat_vol).split(" ")[0].replace(",","."))
								- parseFloat(String(offer.curr_vol).split(" ")[0].replace(",","."))
								).toFixed(1)
								).replace(".",",") + " " + String(offer.curr_vol).split(",")[1].substring(1,10000)
							]}
							width={'80px'} 
							showOption='show'
							/>
							{offer.strat_vol}</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray' style={{cursor: 'pointer', position: 'relative',
					fontSize: '11px'}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					<ToolTipCustomList 
					// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
					list={["$ un: $"+offer.tooltip_base_pxu, "$ venta: $"+offer.tooltip_base_sp]}
					width={'100px'} 
					showOption='show'
					/>
						{offer.strat_io_vol}
					</div>
				</td>

				<td>
					<div className='display_flex gray' style={{cursor: 'pointer', position: 'relative',
					fontSize: '11px'}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					<ToolTipCustomList 
					// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
					list={["$ un: $"+offer.tooltip_current_pxu, "$ venta: $"+offer.tooltip_current_sp]}
					width={'100px'} 
					showOption='show'
					/>
						${offer.curr_price}
					</div>
				</td>

				<td>
					<div className='display_flex gray' style={{cursor: 'pointer', position: 'relative',
					fontSize: '11px'}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					<ToolTipCustomList 
					// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
					list={["$ un: $"+offer.tooltip_optimization_pxu, "$ venta: $"+offer.tooltip_optimization_sp]}
					width={'100px'} 
					showOption='show'
					/>
						${offer.opt_price}
					</div>
				</td>

				<td>
					<div
						className='head_box wrap_input'
						style={{transform: 'translateX(5px)'}}
					>
						<span style={{fontSize: 11}}>$</span>
						<input
							key={offer.id+"_strprice"}
							type='text'
							value={String(estr).replace("$","")}
							onChange={(e) => {
								setOnEdit(true)
								setestr(e.target.value)
							}}
							// formatNumberES
							onKeyUp={(e) =>{
								if (e.key === 'Enter') {
									setOnEdit(false)
									// Lógica que quieres ejecutar cuando se presiona Enter
									// alert(e.target.value.replace("$","").replace(".","").replace(",","."))
									setestr(formatNumberES(e.target.value.replace("$","").replace(".","").replace(",",".")))
									let desct = parseFloat(1 - (parseInt(e.target.value.replace("$","").replace(".","").replace(",","."))/parseInt(String(offer.base_price).replace(".","").replace(",",".").replace("$",""))), 1) * 100
									console.log("Ejecutando calculo front")
									setdcto(desct.toFixed(1))
									e.target.blur();
									rowChangeFunctionDesc()
								}	
							}}	
							onBlur={(e) => {
								// Lógica que quieres ejecutar cuando se presiona Enter
								if(onEdit){
									// alert(e.target.value.replace("$","").replace(".","").replace(",","."))
									setOnEdit(false)
									setestr(formatNumberES(e.target.value.replace("$","").replace(".","").replace(",",".")))
									let desct = parseFloat(1 - (parseInt(e.target.value.replace("$","").replace(".","").replace(",","."))/parseInt(String(offer.base_price).replace(".","").replace(",",".").replace("$",""))), 1) * 100
									console.log("Ejecutando calculo front")
									setdcto(desct.toFixed(1))
									e.target.blur();
									rowChangeFunctionDesc()
								}
							}}	
						/>
					</div>
				</td>
				<td className='border-gray-custom-blue'>
					<div
						className='head_box '
						style={{transform: 'translateX(18px)'}}
					>
						<p style={{fontSize: 11}}>{offer.strat_curr}</p>
					</div>
				</td>
				<td>
					<div className={`display_flex ${parseFloat(String(offer.curr_mg).replace("$","").replace(",", ".")) > 0 ? 'green' : 'orange'}`}>
					<p className='ten'>
					{parseFloat(String(offer.curr_mg).replace("$","").replace(",",".")) > 0 ? 
						<img src={greenarrow} alt='' />
						:
						<img src={orangearrow} alt='' />}
						{offer.curr_mg}</p>
					</div>
				</td>

				<td >
					<div className={`display_flex  ${parseFloat(String(offer.opt_mg).replace("$","").replace(",", ".")) > 0 ? 'green' : 'orange'}`}>
					{parseFloat(String(offer.opt_mg).replace("$","").replace(",",".")) > 0 ? 
						<img src={greenarrow} alt='' />
						:
						<img src={orangearrow} alt='' />}
						<p className='ten'>{offer.opt_mg}</p>
					</div>
				</td>

				<td className='border-gray-custom-blue'>
					<div className={`display_flex   ${parseFloat(String(offer.strat_mg).replace("$","").replace(".","").replace(",", ".")) > 0 ? 'green' : 'orange'}`} style={{cursor: 'pointer', position: 'relative',
					fontSize: '11px', color: '#13acaa', fontWeight: 500}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					
					
					{parseFloat(String(offer.strat_mg).replace("$","").replace(".","").replace(",",".")) > 0 ? 
						<img src={greenarrow} alt='' />
						:
						<img src={orangearrow} alt='' />}
						
						<ToolTipCustomList 
							// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
							list={["RO Total: $"+offer.tooltip_total_ro, "RO Unit: $"+offer.tooltip_unit_ro, "$ Crit:"+offer.critical_price]}
							width={'120px'} 
							showOption='show'
							/>
							<p>{offer.strat_mg}</p>
					</div>
				</td>

				<td>
					<div className={`display_flex   
				${parseFloat(String(offer.strat_ro).replace(".","").replace(",", ".")) > 0 ? 'green' : 'orange'}`}>
					{parseFloat(String(offer.strat_ro).replace(".","").replace(",", ".")) >
					 0 ? 
						<img src={greenarrow} alt='' />
						:
						<img src={orangearrow} alt='' />}
						<p className='ten'>{offer.strat_ro}</p>
					</div>
				</td>

				<td>
					<div className='display_flex  gray'>
						
						<p className='ten'>{offer.uplift}</p>
					</div>
				</td>

				<td className='border-gray-custom-blue'>
				<div className='display_flex gray'>
							<p className='ele'>{offer.incr_ro}%</p>
						</div>
				</td>
				<td>
					<div className='display_flex gray'>
							<p className='ele'>${offer.recommended_pvp}</p>
						</div>
					</td>
				<td className='border-gray-custom-blue'>
					<div className='display_flex gray'>
						<p className='ele'>{offer.elasticity}%</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<a href='#' className='ele'
							onClick={(e) => {
								setPromotionalVariable(true)
							}}
						>
							{resultado.noVacias}/{resultado.vacias + resultado.noVacias} 
						</a>
						<img
							src={closing}
							alt=''
							onClick={(e) => {
								setDeleteRow(offer)
								setdeletestate(true)
							}}
							style={{cursor: 'pointer'}}
						/>
					</div>
				</td>
				
			</tr>
			{ProductTableInnerState && 
							offerRow.product_rows.map((row) =>{
								return <ProductTableInnerTradicional data={row} activateOffice={activateOffice}
									offerId={offerRow.offer_product_id}
									key={row.id}
									rowChangeFunctionDescOffice={rowChangeFunctionDescOffice}
									setModeloPopupState={setModeloPopupState}
									setProduct={setProduct}
									// setOnEdit={setOnEdit}
								/>
							})
						}
			</>
	)
}
