import React from "react";

export const SwitchButton = ({ setActive, active, activateOffice, id_office, offerId}) => {

  
  return (
    <div
      className={`SwitchButton ${active && "active"}`}
      onClick={(e) => {
        if(!active){
          activateOffice(id_office, 1, offerId)
        }else{
          activateOffice(id_office, 0, offerId)
        }
        setActive(!active);
      }}
    >
      <span className="SwitchButtonBall"></span>
    </div>
  );
};
